<template>
  <div>
    <h1 class="text-h4">Управление товарами</h1>
    <v-divider class="my-2"></v-divider>
    <v-form v-model="form.valid" :disabled="busy" @submit.prevent="submitForm">
      <v-card>
        <v-card-title>Свойства товара {{ itemCode }}</v-card-title>
        <v-card-text>

          <v-card v-for="p in form.data" :key="p.key" outlined class="d-flex mb-1">
            <v-card-text>
              <v-row dense>
                <v-col cols="12" lg="4" sm="6">
                  <v-autocomplete
                    v-model="p.id"
                    dense
                    label="Название"
                    item-text="name"
                    item-value="id"
                    :items="props"
                    :rules="form.rule.id"
                    clearable
                    return-object
                  />
                </v-col>
                <v-col cols="12" lg="8" sm="6">
                  <div class="d-flex">
                    <v-text-field
                      v-model="p.value"
                      dense
                      label="Значение"
                      counter="64"
                      :rules="form.rule.value"
                    />
                    <div class="ml-1 align-self-center">шт.</div>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn color="error" icon @click="deletePropertyLine(p.key)">
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-card v-if="!form.data.length" outlined>
            <v-card-text class="font-italic pa-4">Пока ничего не добавлено. Используйте кнопку ниже для добавления и редактирования нового свойства</v-card-text>
          </v-card>
          <v-btn
            small
            color="primary"
            outlined
            class="mt-2"
            @click="addPropertyLine"
          >
            <v-icon left>mdi-file-plus-outline</v-icon>
            Добавить
          </v-btn>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="$router.back()">Назад</v-btn>
          <!--          <v-btn :to="{name: 'item.view', params: { id }}">Отмена</v-btn>-->
          <v-btn color="primary" :disabled="!form.valid" type="submit">Отправить</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { dashboardPageCommonAttributes } from '@/mixins/DashboardPageCommonAttributes'
import { mapActions, mapState } from 'vuex'
import { ruleRequired } from '@/utils/FormRules'
import { value } from 'lodash/seq'
import ItemPropsService from '@/service/ItemPropsService'
import ToastService from '@/service/ToastService'

export default {
  ...dashboardPageCommonAttributes,
  name: 'ItemPropsEditPage',
  props: {
    id: {
      type: [String],
      required: true
    }
  },
  data: function() {
    return {
      form: {
        valid: true,
        data: [],
        rule: {
          id: [ruleRequired(), this.validateUniqueProperty],
          value: [ruleRequired()]
        }
      }
    }
  },
  computed: {
    ...mapState('item_props_edit', ['item', 'props', 'busy']),
    itemCode() {
      if (this.item) {
        return null
      }

      if (this.item.codeBs) {
        return this.item.code + ' (' + this.item.codeBs + ')'
      }

      return this.item.code
    }
  },
  created() {
    this.fetchData({ id: this.id })
      .then(() => {
        // this.form.data.name = this.item.name
        // this.form.data.tags = this.item.tags.map((t) => t.id)

        this.form.data = this.item.props.map((ip) => {
          const propValue = this.props.find((p) => p.id === ip.id)

          return { key: ip.id, id: propValue, name: ip.name, value: ip.value }
        })
      })
  },
  methods: {
    ...mapActions('item_props_edit', ['fetchData', 'updateItemProps']),
    submitForm() {
      const props = this.form.data.map((i) => Object.assign({}, { id: i.id.id, value: i.value }))

      this.updateItemProps({ form: props })
        .then(() => {
          this.$router.back()
          // this.$router.push({ name: 'item.view', params: { id: this.id } })
          ToastService.success('Данные сохранены')
        })
        .catch((error) => {
          console.warn(error)
          ToastService.error('Что-то пошло не так')
        })
    },
    addPropertyLine() {
      const key = Date.now().toString() + '-' + Math.floor(Math.random() * 1000)

      this.form.data.push({ key, id: null, name: null, value: null })
    },
    deletePropertyLine(key) {
      this.form.data = this.form.data.filter((p) => p.key !== key)
    },
    validateUniqueProperty (value) {
      if (!value) {
        return true
      }

      const count = this.form.data.filter((p) => p.id.id === value.id)

      if (count.length > 1) {
        return 'Такое свойство уже задано.'
      }

      return true
    }
  }
}
</script>
